.nav-link svg {
    vertical-align: baseline;
    margin-right: 5px;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: var(--light-blue);
    /* border-bottom: 1px solid var(--light-blue); */
}

.center-nav{
    display: flex;
    width: 100%;
}

.center-nav .navbar-collapse {
    justify-content: center;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  border-bottom: 1px solid var(--light-blue);
}


.user-nav{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}