.switch-box-elements {
  display: flex;
  align-items: center;
}

.switch-box-elements .label-span {
  padding-right: 10px;
}

.required.form-control {
  border-color: #000;
}


.form-group .select-box-elements .dropdown-wrapper {
  width: 100%;
}
.form-group .select-box-elements .dropdown-wrapper .dropdown {
  width: 100%;
}

.form-group .select-box-elements .dropdown button {
  width: 100%;
  text-align: left;
}

.form-group .select-box-elements .dropdown button .caret {
  right: 10px;
  top: 45%;
  position: absolute;
}
