.attachments-list {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.attachments-list .attachments-list-item {
    margin: 10px;
    width: 250px;
    max-width: 250px;
    height: 180px;
    border: 0px;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
}

.attachments-list .attachments-list-item .icon {
    font-size: 7rem;
}

.attachments-list .attachments-list-item .icon:hover {
    background-color: #cccccc71;
    cursor: pointer;
}

.icon {
    font-size: 20px;
    text-align: center;
}


@media screen and (max-width: 600px) {

    .icon {
        font-size: 12px;
    }

    .actions i {
        font-size: 12px;
    }
}