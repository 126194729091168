.confirm-visit {
    margin-top: 100px;
    background-color: #fff;
}

.confirm-visit h5 {
    text-align: center;
    margin-bottom: 20px;
}

.confirm-visit .row {
    margin-top: 20px;
}

.button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.confirm-visit .button-wrapper .btn.btn-primary {
  margin-left: 20px;
}

@media screen and (max-width: 600px) {

    .confirm-visit button {
        font-size: small;
    }


}