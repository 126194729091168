@import url('https://fonts.googleapis.com/css?family=Noto+Sans+HK:400,700&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}
/* 
  --dark-blue: #001C55;
  --light-blue: #4392F1;
*/
:root {
  --dark-blue: #001C55;
  --light-blue: #4392F1;
  --light-blue-hover: #0069d9;
  --light-blue-border: #007bff;
  --light-blue-border-hover: #0062cc;
  --icon-color: #212529;
  --icon-color-hover: #363d49;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

button:disabled {
  opacity: 0.7;
}

.page-container {
    margin: 0 auto;
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.margin-div {
    margin-bottom: 20px;
}

.help-block-after-input {
    background-color: #f2dede !important;
    color: #a94442;
}

.dropdown-menu li.danger {
    background-color: #d9534f;
    border-color: #d43f3a;
}

.rc-time-picker.form-control {
  padding: 0;
}

.rc-time-picker.form-control > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555 !important;
}

div.work-space {
  padding-left: 20px;
  padding-right: 20px;
}

.root-row {
  margin: 0;
}
.root-row > div {
  padding-left: 0;
  padding-right: 0;
}

.btn-success {
  background-color: #68cd7b;
}

.jumbotron {
  background-color: #fff;
  padding: 2rem 1rem;
}

*:focus {
  outline: none;
 }


@media only screen and (max-width: 600px) {

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  .jumbotron{
    padding: 2rem 1rem;
  }
}