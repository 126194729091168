.layout-module-view-with-menu {
    display: flex;
}

.layout-module-view-with-menu .menu-side-bar {
    width: 250px;
    margin-top: 20px;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav {
    border-left: solid 1px var(--dark-blue);
    border-right: solid 1px var(--dark-blue);
    border-top: solid 1px var(--dark-blue);
}
.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a {
  margin-top: 0;
  cursor: pointer;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a:hover {
  background-color: var(--light-blue);
  color: #fff;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a.active {
  background-color: var(--light-blue);
    color: #fff;
}
.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a{
    border-radius: 0;
    border-bottom: solid 1px var(--dark-blue);
    color: #000;
}


.layout-module-view-with-menu > .content-side {
    flex: 1 0 auto;
}

.layout-module-view-with-menu .content-side > .row {
    margin-left: 0;
    margin-right: 0;
}