.file-uploader {
    margin: 10px;
}

.file-uploader.drop-uploader {
    border: dashed 1px #023c81;
    border-radius: 10px;
    margin: 20px auto 0 auto;
    width: 80%;
    height: 150px;
    max-height: 150px;
    position: relative;
    background-color: #023b811f;
}

.file-uploader.drop-uploader .place-holder {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #023b811f;
}

.file-uploader.drop-uploader .place-holder > div > span {
    position: relative;
    display: inline-block;
    color: #023c81;
    cursor: pointer;
}



.file-uploader.drop-uploader .files-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-flow: column wrap;
    flex-wrap: wrap;
    height: 100%;
}

.file-uploader.drop-uploader .files-list li {
    list-style: none;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.9);
}


.file-uploader.drop-uploader .upload-button-wrapper {
    position: absolute;
    right: -20px;
    bottom: -20px;
    height: 40px;
    width: 95px;
    z-index: 5;
}

.file-uploader.drop-uploader .upload-button-wrapper .btn.btn-success {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 26px;
    padding: 0;
    background-color: #5cb85c;
    border: #5cb85c;
    border-radius: 30px;
    color: #fff;
}

.file-uploader.drop-uploader .upload-button-wrapper .icon-button {
    width: 40px;
    height: 40px;
    background-color: #0f509e;
    color: #fff;
    margin-right: 10px;
    border-radius: 40px;
    position: relative;
}

.file-uploader.drop-uploader .upload-button-wrapper .icon-button  input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.file-uploader.drop-uploader .progress {
    margin: 0;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
}
