@import url(https://fonts.googleapis.com/css?family=Noto+Sans+HK:400,700&display=swap);
body {
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}
/* 
  --dark-blue: #001C55;
  --light-blue: #4392F1;
*/
:root {
  --dark-blue: #001C55;
  --light-blue: #4392F1;
  --light-blue-hover: #0069d9;
  --light-blue-border: #007bff;
  --light-blue-border-hover: #0062cc;
  --icon-color: #212529;
  --icon-color-hover: #363d49;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

button:disabled {
  opacity: 0.7;
}

.page-container {
    margin: 0 auto;
}

.justify-content-center {
    justify-content: center!important;
}

.margin-div {
    margin-bottom: 20px;
}

.help-block-after-input {
    background-color: #f2dede !important;
    color: #a94442;
}

.dropdown-menu li.danger {
    background-color: #d9534f;
    border-color: #d43f3a;
}

.rc-time-picker.form-control {
  padding: 0;
}

.rc-time-picker.form-control > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555 !important;
}

div.work-space {
  padding-left: 20px;
  padding-right: 20px;
}

.root-row {
  margin: 0;
}
.root-row > div {
  padding-left: 0;
  padding-right: 0;
}

.btn-success {
  background-color: #68cd7b;
}

.jumbotron {
  background-color: #fff;
  padding: 2rem 1rem;
}

*:focus {
  outline: none;
 }


@media only screen and (max-width: 600px) {

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  .jumbotron{
    padding: 2rem 1rem;
  }
}
.nav-brand img{
    width: 120px;
    margin-right: 10px;
}

.nav-brand {
    font-size: 14px;
}

@media (max-width:764px){
    .nav-brand img{
        width: 100px;
    }
}



.navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-nav .nav-link a :hover {
    color: var(--light-blue);
}

.login-btn{
    margin-right: 10px;
}
.help-block-after-input {
  border: solid 1px #a94442;
  border-top: none;
  margin-top: -2px;
  background-color: #a94442;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  padding-left: 10px;
  flex: 0 0 100%;
}
.help-block-after-input li {
  list-style: none;
  margin-left: 0px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.react-datepicker__day {
  width: 3rem !important;
  line-height: 2rem !important;
  font-size: 1rem;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}


.fa.fa-check-square-o,
.fa.fa-square-o {
  cursor: pointer;
}
.label-select {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 15px;
}

.label-select .dropdown.btn-group,
.label-select > .label-select-button {
  flex: 0 0 50%;
}

.label-select .dropdown.btn-group button{
  width: 100%;
}

.label-select > .label-select-button,
.label-select .dropdown.btn-group button {
  text-align: left;
}

.label-select .dropdown.btn-group button .caret {
  float: right;
  margin-top: 2%;
}

.label-select.danger > .label-select-button,
.label-select.danger > .dropdown.btn-group button,
.label-select.danger > .dropdown.btn-group:hover button {
  background-color: rgba(232,86,86,.85) !important;
}

.days-of-week-selector {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
}

.days-of-week-selector  > li:hover {
  background: rgba(255, 255, 255, 0.3);
}
.days-of-week-selector  > li
{
  margin: 2px;
  background: rgba(255, 255, 255, 0.1);
  flex-grow: 1;
  flex-basis: 0;
}
.days-of-week-selector  > li > button{
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.days-of-week-selector  > li.active {
  background-color: #337ed1;
}

.between-time-selector {
  display: flex;
}

.between-time-selector .rc-time-picker {
  flex-grow: 1 !important;;
  flex-basis: 0 !important;
}

.rc-time-picker-input {
  color: #fff !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  /* flex-grow: 1 !important;;
  flex-basis: 0 !important; */
}
.rc-time-picker-panel-select {
  overflow-x: hidden;
}
.rc-time-picker-panel-select::-webkit-scrollbar
{
	width: .5em;
	height: .5em;
	background-color: rgba(0,0,0, 0.3);
}

.rc-time-picker-panel-select::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.rc-time-picker-panel-select li {
  color: #000;
}

.rdw-editor-wrapper {
  background-color: #fff;
}
.rdw-dropdown-selectedtext,
.rdw-editor-toolbar,
.rdw-dropdown-selectedtext:hover,
.rdw-dropdown-optionwrapper {
  color: #000;
}
.rdw-embedded-modal-link-input,
.rdw-image-modal-url-input {
  width: auto !important;
  height: auto !important;
  margin: 0px !important;
  border: 1px solid #6d6d6d !important;
  border-radius: 2px !important;
  padding: 0 5px !important;
}

.DraftEditor-editorContainer {
  color: #000;
  padding: 5px;
}


.form-wizard > .row {
    margin-left: -15px !important;
    margin-right: -15px !important;
}




.checkbox-list {
    margin: 0px;
    padding: 0px 6px;
    border: 1px solid transparent;
    border-radius: 5px;
    max-height: 150px;
    background-color: rgba(0,0,0,.15);
    overflow-y: scroll;
}
.checkbox-list li{
    list-style: none;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.checkbox-list li.disabled{
    background-color: rgba(0, 0, 0, 0.5);
}
.checkbox-list li.disabled li {
    background-color: transparent;
}

.checkbox-list div.checkbox{
    margin: 0px;
    padding-top: 1px;
    padding-top: 1px;
}

.checkbox-list ul {
    margin: 0px;
    padding: 0px;
}
.form-group.no-marin{
    margin: 0px;
}

.loader,
.loader:before,
.loader:after {
    background: #023c81;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.loader {
    color: #023c81;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 10px;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}
.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 1.5em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.borderSeparatorLeft {
    border-right: solid 1px #023c81;
    margin-right: -15px;
    padding-right: 30px;
}

.borderSeparatorRight {
    margin-left: -15px;
    padding-left: 30px;
}

@media (max-width:994px){
    .borderSeparatorLeft {
        border-right: none;
    }
}

.notifications{
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
}

.confirm-visit {
    margin-top: 100px;
    background-color: #fff;
}

.confirm-visit h5 {
    text-align: center;
    margin-bottom: 20px;
}

.confirm-visit .row {
    margin-top: 20px;
}

.button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.confirm-visit .button-wrapper .btn.btn-primary {
  margin-left: 20px;
}

@media screen and (max-width: 600px) {

    .confirm-visit button {
        font-size: small;
    }


}
.login-card {
  margin-top: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.login-card h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.forgot-pass-link {
  font-size: 14px;
  text-decoration: underline;
}

.login-card button {
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.login-card .form-label {
  margin-top: 1rem;
  font-weight: 600;
}

.login-card .login-form {
  border-top: 1px solid #d9dadc;
}

/* Forgot Password */

.forgot-password {
  margin-top: 100px;
  background-color: #fff;
}

.forgot-password .form-label {
  margin: 0px;
  font-weight: 600;
}

.forgot-password button {
  width: 50%;
}

.forgot-password h1 {
  text-align: center;
  font-weight: 800;
}

.forgot-password p {
  text-align: center;
}

.user-working-day-time-picker {
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
}

.user-working-day-table thead th {
  padding-bottom: 10px;
}

.user-working-day-table tbody td {
  padding-bottom: 5px;
}

.close {
  font-size: 32px;
}

.doctors-dropdown-list {
  font-weight: 400;
}
.switch-box-elements {
  display: flex;
  align-items: center;
}

.switch-box-elements .label-span {
  padding-right: 10px;
}

.required.form-control {
  border-color: #000;
}


.form-group .select-box-elements .dropdown-wrapper {
  width: 100%;
}
.form-group .select-box-elements .dropdown-wrapper .dropdown {
  width: 100%;
}

.form-group .select-box-elements .dropdown button {
  width: 100%;
  text-align: left;
}

.form-group .select-box-elements .dropdown button .caret {
  right: 10px;
  top: 45%;
  position: absolute;
}

.search-user-box {
  max-height: 100px;
  overflow: auto;
}

.users-suggestions {
  color: #4098ff;
}

.custom-toolbar {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 18px;
    position: relative;
}

.custom-toolbar .buttons-around-label {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.custom-toolbar .buttons-around-label .label {
    color: #000;
    font-size: 100%;
}

.custom-toolbar .view-items {
    flex: 0 1 auto;
    margin-left: auto;
    margin-right: 20px;
}

.custom-toolbar .current-clinic {
    margin-right: 5px;
}

.custom-toolbar button {
    border-radius: 10px;
}
.custom-toolbar .current-doctor {
  padding-left: 10px;
  margin-right: 5px
}
.calendar-toolbar-custom-button-select {
  position: relative;
}
.calendar-toolbar-custom-button-select-dropdown {
  position: absolute;
  background-color: #ffffff;
  padding: 0;
  left: 0;
  top: 100%;
  text-align: left;
  min-width: 100%;
}

.calendar-toolbar-custom-button-select-dropdown li i {
  color: #023c81;
}

.calendar-toolbar-custom-button-select > span {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (max-width: 600px) {
    .custom-toolbar {
      display: block;
      font-size: 10px;
  }

  .custom-toolbar .btn {
      font-size: 10px;
  }
}
.custom-event-wrapper .datetime {
    font-size: 11px;
    color:#f7f7f7dc;
    font-weight: 400;
}

.custom-event-wrapper .patient-info {
    color: #e9e9e9;
    font-weight: 500;
    font-size: 14px;
}

.event-info-popup {
  position: absolute;

  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  min-width: 200px;
  max-width: calc(50%);
  color: #000000;
  padding: 10px;
  min-height: 200px;
  box-shadow: 0px 7px 40px -5px rgba(69, 60, 119, 0.75);
  z-index: 100;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

/* Moze sie przydać

.event-info-popup:before {
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
  background-color: #fbfcdb;
  content: ' ';
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
*/


.event-info-popup .event-info-popup-quick-menu {
  position: absolute;
  top: -1px;
  left: 100%;
  background-color: #023c81;
  z-index: 99999;
  height: 101%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 7px;
}

.event-info-popup .event-info-popup-quick-menu .bottom-icon {
  position: absolute;
  bottom: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon {

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.event-info-popup .event-info-popup-quick-menu .icon a {
  color: #fff;
  font-size: 1.5rem;
}
.event-info-popup .event-info-popup-quick-menu .icon button {
  background-color: transparent;
  border: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #fff;
}

.event-info-popup .event-info-popup-quick-menu .icon a :hover{
  transform: scale(1.2);
}

.event-info-popup .event-info-popup-quick-menu .icon.alert {
  padding: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon.alert button {
  color: #c42a2a;
  padding: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon-alert button :hover {
  transform: scale(1.2);
}

.event-property-name {
  padding-right: 3px;
  font-weight: 400;
}

.event-property-value {
  font-weight: 600;
  color: #222222;
}

.event-property-name i {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
  color: #023c81;
}

.event-property-name .icon-text {
  font-size: 10px;
  font-weight: 300;
}

.event-property-description {
  display: block;
  padding: 5px;
  border-radius: 5px;
  background-color: #023b811f;
  min-height: 30px;
  font-size: 12px;
  font-weight: 500;
}

.event-info-popup hr {
  border-top: 1px solid #023c81;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fc {
  background-color: #ffff;
}
.fc .fc-toolbar.fc-header-toolbar {
  z-index: 3;
}

.fc-view-harness ::-webkit-scrollbar {
  display: none;
}

.fc-view-harness {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-inline: 20px;
}
.rbc-calendar-wrapper {
  background-color: #ffff;
  height: 90vh;
}

.fc-header-toolbar {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.fc .fc-toolbar-title {
  display: inline;
  font-size: 1.15em;
  font-weight: 500;
  color: #171616;
}
.fc-col-header {
  border-bottom: solid 1px rgba(128, 128, 128, 0.35);
}

.fc-timegrid-col.fc-day {
  border-left: solid 1px rgba(128, 128, 128, 0.35);
}

.fc .fc-timegrid-col-frame .fc-non-business {
  background: rgba(0, 0, 0, 0.4);
}

.fc-timegrid-event {
  border: 1px solid #265985;
  border-radius: 5px;
  border-left: 6px solid #023c81;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.fc-timegrid-event.closed {
  background-color: #605f5f;
  border-color: #171616;
}
.fc-timegrid-event.closed .custom-event-wrapper .patient-info,
.fc-timegrid-event.closed .custom-event-wrapper .datetime {
  color: #171616;
}

.fc-timegrid-event.approved {
  background-color: #589058;
  border-color: #244024;
}

.rbc-calendar-wrapper {
  position: relative;
  padding-top: 15px;
}

.rbc-calendar-wrapper .custom-toolbar {
  position: absolute;
  top: 0;
}

.custom-event-wrapper {
  position: absolute;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  margin-left: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}
.fc-timegrid-event {
  background-color: #2d97ee;
}
.fc-timegrid-event.active .custom-event-wrapper:hover {
  height: auto;
  overflow: visible;
  background-color: #2d97ee;
  background-color: var(--fc-event-bg-color, #2d97ee);
}

.fc-daygrid-event-harness,
.month-view-cluster.month-view-event,
.custom-event-wrapper-month {
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-event-wrapper-month::after {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  font-family: Font Awesome\ 5 Free;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\F500";
  font-size: 300%;
  font-weight: 900;
  color: #2d97ee;
  min-height: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.custom-event-wrapper-month .title {
  position: absolute;
  top: 50%;
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 60%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.fc-daygrid-day-events {
  position: absolute !important;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
}

.fc-header-toolbar.fc-toolbar > div:nth-child(1)
.fc-header-toolbar.fc-toolbar > div:nth-child(3) {
  width: 25%;
}
.fc-header-toolbar.fc-toolbar > div:nth-child(2) {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.fc .fc-timegrid-slot {
  height: 2.4em;
}

.btn-primary{
  background-color: #ffff;
  border: 1px solid #023c81;
  color: #023c81;
  border-radius: 10px;
  outline: none;

}

.fc-today-button.btn.btn-primary,
.fc-today-button.btn.btn-primary:focus,
.fc-today-button.btn.btn-primary:hover{
  background-color: #FFF9DA;
  color: #eea508;
  border-bottom: 1px solid #eea508;
  border-top: 1px solid #eea508;
  border-left: 1px solid #eea508;
}
.fc-today-button.btn.btn-primary:disabled {
  background-color: #FFF9DA;
  color: #eea508;
  border-bottom: 1px solid #eea508;
  border-top: 1px solid #eea508;
  border-left: 1px solid #eea508;
}

.fc-configModal-button.btn.btn-primary:focus,
.fc-nearestFreeTermin-button.btn.btn-primary:focus {
  background-color: #fff;
  color: var(--dark-blue);
}

.fc-prev-button.btn.btn-primary:focus,
.fc-next-button.btn.btn-primary:focus {
  background-color: #fff;
  color: var(--dark-blue);
}

@media screen and (max-width: 800px) {
  .fc .fc-toolbar-title {
    font-size: 1em;
  }

  .custom-event-wrapper-month::after {
    font-size: 150%;
    top: 30%
  }

  .custom-event-wrapper-month .title  {
    width: 15px;
    height: 15px;
    top: 75%;
  }
}


.table-buttons {
  white-space: nowrap;
}

.google-map-wrapper{
  position: relative;
}
.google-search-bar {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  background-color: rgba(0,0,0,.15);
  /*background-color: rgba(51, 126, 209, 0.7);*/
  z-index: 99999999;
}
.google-search-bar input,
.google-search-bar button
{
  /*color: #000 !important;*/
}

.google-search-bar ul {
  max-height: 170px;
  overflow-y: scroll;
  list-style: none;
  padding: 10px;
}
.google-search-bar ul li{
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 5px;
}
.google-search-bar ul li:hover{
  background-color: rgba(255, 255, 255, 0.5);
}

.google-search-bar ul::-webkit-scrollbar
{
  width: .5em;
  height: .5em;
  background-color: rgba(0,0,0, 0.3);
}

.google-search-bar ul::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
}

.back-button {
  float: right;
}

.patient-search {
    margin-top: 40px;
    margin-bottom: 40px;
}

.search-patient-bar {
    border-radius: 10px;
    width: 80%;
    background-color: #ffff;
    min-height: 40px;
}

.add-patient-btn {
    border-radius: 10px;
    border: 0px;
    font-size: 14px;
    background-color: #023c81;
    color: #ffffff;

}

.search-patient-btn {
    border-radius: 10px;
    border: 0px;
    background-color: #757373;
    color: #ffffff;
}

.add-patient-btn:hover {
    transform: scale(1.1);
    color: #fff;
}

.attachments-list .actions {
    text-align: center;
}

.table td, .table th {
    vertical-align: middle;
}

.patient-navbar-wrapper .patient-navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}


.patient-navbar-wrapper {
  border-top: solid 1px var(--dark-blue);
  border-radius: .3rem;
  display: flex;
  justify-content: space-between;
}

.patient-navbar-wrapper .patient-navbar.left a:first-child {
  border: none;
  border-bottom-left-radius: .3rem;
  border-top-left-radius: .3rem;
}

.patient-navbar-wrapper .patient-navbar.right {
  padding-right: 0;
}

.patient-navbar-wrapper .patient-navbar.right a:last-child {
  border-bottom-right-radius: .3rem;
  border-top-right-radius: .3rem;
}
.patient-navbar-wrapper .patient-navbar.right a:first-child {
  border: none;
}


.patient-navbar-wrapper .patient-navbar a {
  color: #000;
  border-left: solid 1px var(--dark-blue) ;
}

.patient-navbar-wrapper .patient-navbar a.disabled {
  color: #a1a0a0;
  cursor: not-allowed;
}

.patient-navbar-wrapper .patient-navbar a.active,
.patient-navbar-wrapper .patient-navbar a:hover {
  color: #fff;
  background-color: var(--light-blue);
}

.patient-navbar-wrapper .patient-navbar.right a.active { 
  background-color: transparent;
  color: #000;
}

.patient-navbar-wrapper .patient-navbar.right a:hover {
  background-color: #ebebeb;
  color: #000;
}

.patient-header-jumbo {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

 .patient-header-jumbo .patient-jambotron-content {
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
   display: flex;
   justify-content: space-between;
}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-left {

}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right {
  margin-left: auto;
  height: 100%;
}


.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right .visits-cards {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right .visits-cards .visits-card {
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  display: flex;
  justify-items: center;
  align-items: center;
}

.patient-header-jumbo .patient-jambotron-menu {
  margin-top: auto;
}


.patient-header-jumbo .patient-jambotron-menu .patient-navbar-wrapper .blob {
  display: inline-block;
  background: var(--light-blue);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
  transform: scale(1);
  -webkit-animation: pulse-black 2s infinite;
          animation: pulse-black 2s infinite;
}

.patient-header-jumbo .patient-jambotron-menu .patient-navbar-wrapper .patient-active-visit {
  display: flex;
  align-items: center;
}
.menu-user .dropdown-toggle {
    opacity: 0;
    border: solid 1px #0f509e;
    height: 50px;
    width: 50px;
}

.menu-user .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: solid 1px #252525;
    background-color: #d8d8d8;
    position: relative;
}
.menu-user .profile-image .initials {
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18pt;
  font-weight: bold;
  margin-left: -1px;
}

.menu-user .profile-image .dropdown {
  position: absolute;
  top: 0;
  left: 0;
}

.menu-content .menu-menu .menu-user .dropdown-menu a {
    padding: 2px 5px;
}

.menu-content .menu-menu .menu-user .dropdown-menu a > a{
    padding: 0;
}

.dropdown-menu.show {
    margin-right: 50%;
    margin-top: auto;
}
.nav-link svg {
    vertical-align: baseline;
    margin-right: 5px;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: var(--light-blue);
    /* border-bottom: 1px solid var(--light-blue); */
}

.center-nav{
    display: flex;
    width: 100%;
}

.center-nav .navbar-collapse {
    justify-content: center;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  border-bottom: 1px solid var(--light-blue);
}


.user-nav{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.notification-wrapper {
  color: #fff;
  padding: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
}

.notification-wrapper .toast {
  background-color: #ffffff;
}

.notification-wrapper .icon-wrapper {
  margin-right: 5px;
  font-size: 1.5rem;
  position: relative;
}

.notification-wrapper .icon-wrapper .icon-button {
    border: none;
    color: #fff;
}

.notification-wrapper .toast-header {
  justify-content: space-between;

}

.notification-wrapper .toast-header .data {
  margin-left: auto;
}

.notification-wrapper .icon-wrapper .badge-wrapper {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 1rem;
}

.notification-wrapper .toast-wrapper {
  position: absolute;
  min-width: 350px;
  top: 110%;
  right: 0;
  z-index: 4;

  overflow: auto;
  max-height: 80vh;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.notification-wrapper .body-wrapper {
  color: #2a2a2a;
}

.toast-wrapper .notification-header{
  color: #2a2a2a;
  background: #fff;
}
.box-button {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-bottom-color: transparent;
  border-width: 0;
}

.icon-button {
  border: solid 1px var(--icon-color);
  padding: 0;
  font-size: 1.2rem;
  width: 2.3rem;
  height: 2.3rem;
  margin-left: 5px;
  color: var(--icon-color);
}

.icon-button .icon-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

}
.icon-button:hover {
  text-decoration: none;
  color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
}

/** icon button small */
.icon-button.btn-small {
  border: none;
  height: 1rem;
  width: 1rem;
}


.icon-button.btn-danger {
    color: #d9534f;
    background-color: transparent;
    border-color: #ff504d;
}
.icon-button.btn-danger:hover {
    background-color: transparent;
    color: #ff504d;
}

/** button normal */


.btn.btn-primary
{
  background-color: #fff;
  color: var(--dark-blue);
}

.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:visited,
.btn.btn-primary:focus,
.btn.btn-primary:focus-visible,
.btn.btn-primary:focus-within,
.btn.btn-primary:hover
{
  color: #fff;
  background-color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}



/** button success */

.btn.btn-success
{
  color: #fff;
  background-color: var(--light-blue);
  border-color: var(--light-blue-border);
}

.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:visited,
.btn.btn-success:focus,
.btn.btn-success:focus-visible,
.btn.btn-success:focus-within,
.btn.btn-success:hover
{
  background-color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.ssi-wrapper {
  position: relative;
  display: flex;
}
.ssi-wrapper .ssi-progress-wrapper {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0px;

}

.ssi-wrapper .ssi-progress-wrapper .ssi-progress-bar {
  height: 2px;
  background-color: #337ed1;
  opacity: 0.7;
  width: 0px;
  box-shadow: 0px 0px 11px rgb(255,255,255);
}

.searchDropdownPos {
  position: relative;

}

.searchDropdown {
  position: absolute;
  margin-top: -1px;
  border: solid 1px #ccc;
  background-color: rgba(255, 255, 255, .8);
  z-index: 999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}

.searchDropdown .input-group{
  border: solid 1px #6e1229;
  border-radius: 5px;
  margin-bottom: 5px;
}

.searchDropdown .options {
  max-height: 170px;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

}
.searchDropdown .options li {
  padding-top: 2px;
  padding-bottom: 4px;
  padding-left: 5px;
  margin: 0;
    font-size: 15px;
  cursor: pointer;
    color: #000;
    border-bottom: solid 1px #d3d3d3;
}
.searchDropdown .options li small {
    font-size: 10px;
    color: rgb(42, 42, 42);
}
.searchDropdown .options li small:before {
    content: '- ';
}

.searchDropdown .options li:hover {
  background-color: rgba(64, 152, 255, 0.8);
}

.searchDropdown .options::-webkit-scrollbar
{
	width: .5em;
	height: .5em;
	background-color: rgba(0,0,0, 0.3);
}

.searchDropdown .options::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
}

/* .guardian-box {
  transition: dispaly 0.25s ease-in;
}

.search-guardian-box {
  padding-bottom: 20px;
}


.visit-main-container {
  position: relative;
}
.visit-main-container .nav-item-save.btn-success a{
  color: #fff;

}
.visit-main-container .nav-item-save.btn-success i {
  margin-left: 5px;
}
.visit-main-container .nav-item-save.btn-success a:hover,
.visit-main-container .nav-item-save.btn-success a:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.end-visit {
  padding: 0;
}

.end-visit > button {
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 0;
}

.search-separator {
  height: 20px;
}

.link-edit {
  margin-right: 20px;
  font-size: 1.7rem;
}

.link-edit-clinic {
  border-radius: 15px;
  background-color: #fff;
  color: #023c81;
  border-color: #023c81;
  font-size: 12px;
  margin-right: 25px;
}

.form-container {
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}

.control-label {
  font-weight: 400;
  padding-left: 10px;
  color: #646464;
  font-size: 14px;
}

.btn .btn-lg .btn-primary {
  border: 1px solid #023c81;
  border-radius: 10px;
  width: 200px;
}

.form-control {
  border-radius: 10px;
  background-color: #fff;
  color: #1a1a1a;
  font-weight: 400;
  border: 1px solid rgb(202, 202, 202);
}

.required.form-control {
  border: 1px solid #818181;
}

.input-group-addon {
  border-radius: 10px;
}


.patients-table {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.Patient-card-btn {
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #023c81;
  border: 1px solid #023c81;
}

.Patient-card-btn:hover {
  color: #fff;
  border: 1px solid #023c81;
  background-color: #023c81;
}

.Patient-header-jumbo {
  margin-top: 60px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 120px;
}

.Patient-jumbo {
  min-height: 400px;
}

.register-form-Patient {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.Patient-jumbotron-icons {
  text-align: left;
}
.Patient-jumbotron-icons .icon {
  display: flex;
} */

.active-visit-btn {
  min-width: 100px;
}

.visit-main-container {
  position: relative;
}

@-webkit-keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.register-form-patient h4 {
  text-align: center;
  color: var(--dark-blue);
}

.visit-note-area,
.visit-note-area:focus {
  min-height: 50px;
  background-color: rgba(2,59,129,.12156862745098039);
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .patients-table {
    font-size: 10px;
    overflow:scroll;
  }

  .patient-card-btn {
    font-size: 10px;
    padding: 4px 8px;
  }

  .form-container {
    font-size: 10px;
    padding: 5px;
    overflow-x: scroll;
  }
}
.jew-main-container {
  width: 100%;
  box-sizing: border-box;
}
.jew-main-container .jew-container {
  background-color: #c5c5c5;
  box-sizing: border-box;
  position: relative;
}

.jew-main-container .jew-container .jew-up-container,
.jew-main-container .jew-container .jew-down-container {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: solid 1px #fff;
  background-color: #e37877;
}
.jew-main-container .jew-container .jew-down-container {
  top: auto;
  bottom: 0;
  border-bottom: none;
  border-top: solid 1px #fff;
}
.jew-main-container .jew-container .jew-teeth-container {
  position: absolute;
  box-sizing: border-box;
}
.jew-main-container .jew-container .jew-teeth-container .jew-teeth-up-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e37877;
  box-sizing: border-box;
}
.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e37877;
  box-sizing: border-box;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-up-container .jew-teeth-up-container-teethes {
  position: absolute;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .jew-teeth-down-container-teethes {
  position: absolute;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template {
  display: flex;
}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper{

}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-wrapper-container {
  height: 100%;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-wrapper-container.active {
  background-color: rgba(230, 230, 230, 0.7);
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part {
  height: 88%;
  display: flex;
  justify-content: center;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .label-part {
  display: flex;
  justify-content: center;
  /* height: 12%; */
}


.teeth-wrapper .label-part button {
  background-color: transparent;
  border: none;
} 

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part .svg-teeth-default
{
  fill:#fff;
  stroke:#000;
  stroke-width:.25;
  stroke-miterlimit:10
}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part .svg-teeth-default:hover
{
  fill: rgba(250, 250, 250, .5);
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .teeth-template .teeth-wrapper .teeth-part .teeth-svg-model {
  margin-bottom: -3px;
  transform: rotate(180deg);
  overflow: hidden;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .teeth-template .teeth-wrapper .teeth-part .teeth-svg-model svg {

}

.teeth-recognition-main {
  background-color: #023c81;
  width: calc(100% - 24px);
  height: 100%;
  max-height: 100%;
  padding: 0 12px;
  position: absolute;
}

.teeth-recognition-main .recognition-box {
  margin-top: -2px;
  height: calc(100% - 64px);
  max-height: 100%;
  padding-bottom: 12px;
}

.teeth-recognition-main .recognition-box .recognition-box-list {
  background-color: #ffffff;
  height: 96%;
  padding-top: 2px;
  overflow: auto;
  position: relative;
}

.teeth-recognition-main .recognition-box .recognition-box-list {
  list-style: none;
  padding-left: 3px;
}

.teeth-recognition-main .recognition-box .recognition-box-list li {
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
}
.teeth-recognition-main .recognition-box .recognition-box-list li:hover {
  background-color: rgba(59, 153, 252, 0.75);
}

.teeth-recognition-main .recognition-box .recognition-box-list li.selected {
  background-color: #3b99fc;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div {
  padding-top: 3px;
  display: flex;

}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .background-element {
  width: 16px;
  height: 16px;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .background-element > div {
  width: 16px;
  height: 16px;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .name-element {
  line-height: 16px;
  padding-left: 3px;

}


.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .remove-element {
  line-height: 16px;
  padding-left: 3px;
  margin-left: auto;
}

.teeth-properties-list table tbody tr.active td {
    background-color: #65a2ec;
}

.teeth-properties-list ul {
    list-style: none;
    padding: 0;
}

.teeth-properties-list ul li {
    display: flex;
    vertical-align: center;
    flex-direction: row;
    margin-bottom: 5px;
    font-size: 13px;
}

.teeth-properties-list ul li .tooth-operation-legend {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #6e1229;
    margin-right: 5px;
}

.teeth-properties-list{
  margin-top: 15px;
}

.element-overall {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.layout-module-view-with-menu {
    display: flex;
}

.layout-module-view-with-menu .menu-side-bar {
    width: 250px;
    margin-top: 20px;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav {
    border-left: solid 1px var(--dark-blue);
    border-right: solid 1px var(--dark-blue);
    border-top: solid 1px var(--dark-blue);
}
.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a {
  margin-top: 0;
  cursor: pointer;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a:hover {
  background-color: var(--light-blue);
  color: #fff;
}

.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a.active {
  background-color: var(--light-blue);
    color: #fff;
}
.layout-module-view-with-menu .menu-side-bar .flex-column.nav>a{
    border-radius: 0;
    border-bottom: solid 1px var(--dark-blue);
    color: #000;
}


.layout-module-view-with-menu > .content-side {
    flex: 1 0 auto;
}

.layout-module-view-with-menu .content-side > .row {
    margin-left: 0;
    margin-right: 0;
}
.file-uploader {
    margin: 10px;
}

.file-uploader.drop-uploader {
    border: dashed 1px #023c81;
    border-radius: 10px;
    margin: 20px auto 0 auto;
    width: 80%;
    height: 150px;
    max-height: 150px;
    position: relative;
    background-color: #023b811f;
}

.file-uploader.drop-uploader .place-holder {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #023b811f;
}

.file-uploader.drop-uploader .place-holder > div > span {
    position: relative;
    display: inline-block;
    color: #023c81;
    cursor: pointer;
}



.file-uploader.drop-uploader .files-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-flow: column wrap;
    flex-wrap: wrap;
    height: 100%;
}

.file-uploader.drop-uploader .files-list li {
    list-style: none;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.9);
}


.file-uploader.drop-uploader .upload-button-wrapper {
    position: absolute;
    right: -20px;
    bottom: -20px;
    height: 40px;
    width: 95px;
    z-index: 5;
}

.file-uploader.drop-uploader .upload-button-wrapper .btn.btn-success {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 26px;
    padding: 0;
    background-color: #5cb85c;
    border: #5cb85c;
    border-radius: 30px;
    color: #fff;
}

.file-uploader.drop-uploader .upload-button-wrapper .icon-button {
    width: 40px;
    height: 40px;
    background-color: #0f509e;
    color: #fff;
    margin-right: 10px;
    border-radius: 40px;
    position: relative;
}

.file-uploader.drop-uploader .upload-button-wrapper .icon-button  input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.file-uploader.drop-uploader .progress {
    margin: 0;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
}

.page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
}

.page-overlay .page-overlay-content {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    overflow: auto;
}
.pdf-viewer-content {
    min-height: 100vh;
    padding-bottom: 40px;
}

.pdf-viewer-content .pages-toolbar{
    position: fixed;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.pdf-viewer-content .pages-toolbar .tool {
    margin: 0 5px 0 5px;
    text-align: center;
}
.image-viewer-content {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.attachments-list {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.attachments-list .attachments-list-item {
    margin: 10px;
    width: 250px;
    max-width: 250px;
    height: 180px;
    border: 0px;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
}

.attachments-list .attachments-list-item .icon {
    font-size: 7rem;
}

.attachments-list .attachments-list-item .icon:hover {
    background-color: #cccccc71;
    cursor: pointer;
}

.icon {
    font-size: 20px;
    text-align: center;
}


@media screen and (max-width: 600px) {

    .icon {
        font-size: 12px;
    }

    .actions i {
        font-size: 12px;
    }
}
.visit-notes {
    min-height: 50px;
    background-color: #023b811f;
    border-radius: 10px;
    padding: 10px;
}
.shwich-button {
  display: -moz-flex;
  display: flex;
  flex:1 0;
}

.shwich-button > label {
  flex: 0 1 100%;
}

.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

.timeline-steps .timeline-step.active .inner-circle {

}

@media (min-width:768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: .25rem dotted #3b82f6;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: .3125rem
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: .25rem dotted #3b82f6;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: .3125rem
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  border: solid 1px transparent;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6
}


.timeline-steps .timeline-step.active .inner-circle {
  border: solid 1px #155724;
  background-color: #c3e6cb;
}

.timeline-steps .timeline-step.active .inner-circle:before {
  border: solid 1px #155724;
  background-color: #d4edda;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  border: solid 1px transparent;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}
.style_Header{
    background-image: linear-gradient(rgb(2,60,129), rgb(64,152,251));
    background-position-x: 0;
    background-position-x: initial;
    background-position-y: 0;
    background-position-y: initial;
    min-height: 680px;
    padding-top: 3rem;
    padding-right: 0px;
}

.style_content{
    color: white;
    text-align: center;
    margin-top: 90px;
}

.trial-btn{
    text-align: center;
}

.offer-3{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -220px;
}

.style-offer {
    position: relative;
    z-index: 5;
    width: 70%;
}

.style-offer img{
    width: 100%;
    align-self: center;
}

.navbar-light .navbar-text {
    color: #fff;
    font-size: 14px;
}
