.style_Header{
    background-image: linear-gradient(rgb(2,60,129), rgb(64,152,251));
    background-position-x: initial;
    background-position-y: initial;
    min-height: 680px;
    padding-top: 3rem;
    padding-right: 0px;
}

.style_content{
    color: white;
    text-align: center;
    margin-top: 90px;
}

.trial-btn{
    text-align: center;
}

.offer-3{
    position: relative;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    margin-top: -220px;
}

.style-offer {
    position: relative;
    z-index: 5;
    width: 70%;
}

.style-offer img{
    width: 100%;
    align-self: center;
}
