.page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
}

.page-overlay .page-overlay-content {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    overflow: auto;
}