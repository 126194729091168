.google-map-wrapper{
  position: relative;
}
.google-search-bar {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  background-color: rgba(0,0,0,.15);
  /*background-color: rgba(51, 126, 209, 0.7);*/
  z-index: 99999999;
}
.google-search-bar input,
.google-search-bar button
{
  /*color: #000 !important;*/
}

.google-search-bar ul {
  max-height: 170px;
  overflow-y: scroll;
  list-style: none;
  padding: 10px;
}
.google-search-bar ul li{
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 5px;
}
.google-search-bar ul li:hover{
  background-color: rgba(255, 255, 255, 0.5);
}

.google-search-bar ul::-webkit-scrollbar
{
  width: .5em;
  height: .5em;
  background-color: rgba(0,0,0, 0.3);
}

.google-search-bar ul::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
}
