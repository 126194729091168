.custom-event-wrapper .datetime {
    font-size: 11px;
    color:#f7f7f7dc;
    font-weight: 400;
}

.custom-event-wrapper .patient-info {
    color: #e9e9e9;
    font-weight: 500;
    font-size: 14px;
}