.box-button {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-bottom-color: transparent;
  border-width: 0;
}

.icon-button {
  border: solid 1px var(--icon-color);
  padding: 0;
  font-size: 1.2rem;
  width: 2.3rem;
  height: 2.3rem;
  margin-left: 5px;
  color: var(--icon-color);
}

.icon-button .icon-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

}
.icon-button:hover {
  text-decoration: none;
  color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
}

/** icon button small */
.icon-button.btn-small {
  border: none;
  height: 1rem;
  width: 1rem;
}


.icon-button.btn-danger {
    color: #d9534f;
    background-color: transparent;
    border-color: #ff504d;
}
.icon-button.btn-danger:hover {
    background-color: transparent;
    color: #ff504d;
}

/** button normal */


.btn.btn-primary
{
  background-color: #fff;
  color: var(--dark-blue);
}

.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:visited,
.btn.btn-primary:focus,
.btn.btn-primary:focus-visible,
.btn.btn-primary:focus-within,
.btn.btn-primary:hover
{
  color: #fff;
  background-color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}



/** button success */

.btn.btn-success
{
  color: #fff;
  background-color: var(--light-blue);
  border-color: var(--light-blue-border);
}

.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:visited,
.btn.btn-success:focus,
.btn.btn-success:focus-visible,
.btn.btn-success:focus-within,
.btn.btn-success:hover
{
  background-color: var(--light-blue-hover);
  border-color: var(--light-blue-border-hover);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
