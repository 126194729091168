/* .guardian-box {
  transition: dispaly 0.25s ease-in;
}

.search-guardian-box {
  padding-bottom: 20px;
}


.visit-main-container {
  position: relative;
}
.visit-main-container .nav-item-save.btn-success a{
  color: #fff;

}
.visit-main-container .nav-item-save.btn-success i {
  margin-left: 5px;
}
.visit-main-container .nav-item-save.btn-success a:hover,
.visit-main-container .nav-item-save.btn-success a:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.end-visit {
  padding: 0;
}

.end-visit > button {
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 0;
}

.search-separator {
  height: 20px;
}

.link-edit {
  margin-right: 20px;
  font-size: 1.7rem;
}

.link-edit-clinic {
  border-radius: 15px;
  background-color: #fff;
  color: #023c81;
  border-color: #023c81;
  font-size: 12px;
  margin-right: 25px;
}

.form-container {
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}

.control-label {
  font-weight: 400;
  padding-left: 10px;
  color: #646464;
  font-size: 14px;
}

.btn .btn-lg .btn-primary {
  border: 1px solid #023c81;
  border-radius: 10px;
  width: 200px;
}

.form-control {
  border-radius: 10px;
  background-color: #fff;
  color: #1a1a1a;
  font-weight: 400;
  border: 1px solid rgb(202, 202, 202);
}

.required.form-control {
  border: 1px solid #818181;
}

.input-group-addon {
  border-radius: 10px;
}


.patients-table {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.Patient-card-btn {
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #023c81;
  border: 1px solid #023c81;
}

.Patient-card-btn:hover {
  color: #fff;
  border: 1px solid #023c81;
  background-color: #023c81;
}

.Patient-header-jumbo {
  margin-top: 60px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 120px;
}

.Patient-jumbo {
  min-height: 400px;
}

.register-form-Patient {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.Patient-jumbotron-icons {
  text-align: left;
}
.Patient-jumbotron-icons .icon {
  display: flex;
} */

.active-visit-btn {
  min-width: 100px;
}

.visit-main-container {
  position: relative;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.register-form-patient h4 {
  text-align: center;
  color: var(--dark-blue);
}

.visit-note-area,
.visit-note-area:focus {
  min-height: 50px;
  background-color: rgba(2,59,129,.12156862745098039);
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .patients-table {
    font-size: 10px;
    overflow:scroll;
  }

  .patient-card-btn {
    font-size: 10px;
    padding: 4px 8px;
  }

  .form-container {
    font-size: 10px;
    padding: 5px;
    overflow-x: scroll;
  }
}