.user-working-day-time-picker {
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
}

.user-working-day-table thead th {
  padding-bottom: 10px;
}

.user-working-day-table tbody td {
  padding-bottom: 5px;
}

.close {
  font-size: 32px;
}

.doctors-dropdown-list {
  font-weight: 400;
}