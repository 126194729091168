.searchDropdownPos {
  position: relative;

}

.searchDropdown {
  position: absolute;
  margin-top: -1px;
  border: solid 1px #ccc;
  background-color: rgba(255, 255, 255, .8);
  z-index: 999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}

.searchDropdown .input-group{
  border: solid 1px #6e1229;
  border-radius: 5px;
  margin-bottom: 5px;
}

.searchDropdown .options {
  max-height: 170px;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

}
.searchDropdown .options li {
  padding-top: 2px;
  padding-bottom: 4px;
  padding-left: 5px;
  margin: 0;
    font-size: 15px;
  cursor: pointer;
    color: #000;
    border-bottom: solid 1px #d3d3d3;
}
.searchDropdown .options li small {
    font-size: 10px;
    color: rgb(42, 42, 42);
}
.searchDropdown .options li small:before {
    content: '- ';
}

.searchDropdown .options li:hover {
  background-color: rgba(64, 152, 255, 0.8);
}

.searchDropdown .options::-webkit-scrollbar
{
	width: .5em;
	height: .5em;
	background-color: rgba(0,0,0, 0.3);
}

.searchDropdown .options::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
}
