.help-block-after-input {
  border: solid 1px #a94442;
  border-top: none;
  margin-top: -2px;
  background-color: #a94442;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  padding-left: 10px;
  flex: 0 0 100%;
}
.help-block-after-input li {
  list-style: none;
  margin-left: 0px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.react-datepicker__day {
  width: 3rem !important;
  line-height: 2rem !important;
  font-size: 1rem;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}


.fa.fa-check-square-o,
.fa.fa-square-o {
  cursor: pointer;
}
.label-select {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 15px;
}

.label-select .dropdown.btn-group,
.label-select > .label-select-button {
  flex: 0 0 50%;
}

.label-select .dropdown.btn-group button{
  width: 100%;
}

.label-select > .label-select-button,
.label-select .dropdown.btn-group button {
  text-align: left;
}

.label-select .dropdown.btn-group button .caret {
  float: right;
  margin-top: 2%;
}

.label-select.danger > .label-select-button,
.label-select.danger > .dropdown.btn-group button,
.label-select.danger > .dropdown.btn-group:hover button {
  background-color: rgba(232,86,86,.85) !important;
}

.days-of-week-selector {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
}

.days-of-week-selector  > li:hover {
  background: rgba(255, 255, 255, 0.3);
}
.days-of-week-selector  > li
{
  margin: 2px;
  background: rgba(255, 255, 255, 0.1);
  flex-grow: 1;
  flex-basis: 0;
}
.days-of-week-selector  > li > button{
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.days-of-week-selector  > li.active {
  background-color: #337ed1;
}

.between-time-selector {
  display: flex;
}

.between-time-selector .rc-time-picker {
  flex-grow: 1 !important;;
  flex-basis: 0 !important;
}

.rc-time-picker-input {
  color: #fff !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  /* flex-grow: 1 !important;;
  flex-basis: 0 !important; */
}
.rc-time-picker-panel-select {
  overflow-x: hidden;
}
.rc-time-picker-panel-select::-webkit-scrollbar
{
	width: .5em;
	height: .5em;
	background-color: rgba(0,0,0, 0.3);
}

.rc-time-picker-panel-select::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.rc-time-picker-panel-select li {
  color: #000;
}

.rdw-editor-wrapper {
  background-color: #fff;
}
.rdw-dropdown-selectedtext,
.rdw-editor-toolbar,
.rdw-dropdown-selectedtext:hover,
.rdw-dropdown-optionwrapper {
  color: #000;
}
.rdw-embedded-modal-link-input,
.rdw-image-modal-url-input {
  width: auto !important;
  height: auto !important;
  margin: 0px !important;
  border: 1px solid #6d6d6d !important;
  border-radius: 2px !important;
  padding: 0 5px !important;
}

.DraftEditor-editorContainer {
  color: #000;
  padding: 5px;
}


.form-wizard > .row {
    margin-left: -15px !important;
    margin-right: -15px !important;
}




.checkbox-list {
    margin: 0px;
    padding: 0px 6px;
    border: 1px solid transparent;
    border-radius: 5px;
    max-height: 150px;
    background-color: rgba(0,0,0,.15);
    overflow-y: scroll;
}
.checkbox-list li{
    list-style: none;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.checkbox-list li.disabled{
    background-color: rgba(0, 0, 0, 0.5);
}
.checkbox-list li.disabled li {
    background-color: transparent;
}

.checkbox-list div.checkbox{
    margin: 0px;
    padding-top: 1px;
    padding-top: 1px;
}

.checkbox-list ul {
    margin: 0px;
    padding: 0px;
}
.form-group.no-marin{
    margin: 0px;
}
