.patient-search {
    margin-top: 40px;
    margin-bottom: 40px;
}

.search-patient-bar {
    border-radius: 10px;
    width: 80%;
    background-color: #ffff;
    min-height: 40px;
}

.add-patient-btn {
    border-radius: 10px;
    border: 0px;
    font-size: 14px;
    background-color: #023c81;
    color: #ffffff;

}

.search-patient-btn {
    border-radius: 10px;
    border: 0px;
    background-color: #757373;
    color: #ffffff;
}

.add-patient-btn:hover {
    transform: scale(1.1);
    color: #fff;
}

.attachments-list .actions {
    text-align: center;
}

.table td, .table th {
    vertical-align: middle;
}

.patient-navbar-wrapper .patient-navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}


.patient-navbar-wrapper {
  border-top: solid 1px var(--dark-blue);
  border-radius: .3rem;
  display: flex;
  justify-content: space-between;
}

.patient-navbar-wrapper .patient-navbar.left a:first-child {
  border: none;
  border-bottom-left-radius: .3rem;
  border-top-left-radius: .3rem;
}

.patient-navbar-wrapper .patient-navbar.right {
  padding-right: 0;
}

.patient-navbar-wrapper .patient-navbar.right a:last-child {
  border-bottom-right-radius: .3rem;
  border-top-right-radius: .3rem;
}
.patient-navbar-wrapper .patient-navbar.right a:first-child {
  border: none;
}


.patient-navbar-wrapper .patient-navbar a {
  color: #000;
  border-left: solid 1px var(--dark-blue) ;
}

.patient-navbar-wrapper .patient-navbar a.disabled {
  color: #a1a0a0;
  cursor: not-allowed;
}

.patient-navbar-wrapper .patient-navbar a.active,
.patient-navbar-wrapper .patient-navbar a:hover {
  color: #fff;
  background-color: var(--light-blue);
}

.patient-navbar-wrapper .patient-navbar.right a.active { 
  background-color: transparent;
  color: #000;
}

.patient-navbar-wrapper .patient-navbar.right a:hover {
  background-color: #ebebeb;
  color: #000;
}

.patient-header-jumbo {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

 .patient-header-jumbo .patient-jambotron-content {
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
   display: flex;
   justify-content: space-between;
}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-left {

}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right {
  margin-left: auto;
  height: 100%;
}


.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right .visits-cards {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.patient-header-jumbo .patient-jambotron-content .patient-jambotron-content-right .visits-cards .visits-card {
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  display: flex;
  justify-items: center;
  align-items: center;
}

.patient-header-jumbo .patient-jambotron-menu {
  margin-top: auto;
}


.patient-header-jumbo .patient-jambotron-menu .patient-navbar-wrapper .blob {
  display: inline-block;
  background: var(--light-blue);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.patient-header-jumbo .patient-jambotron-menu .patient-navbar-wrapper .patient-active-visit {
  display: flex;
  align-items: center;
}