
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-nav .nav-link a :hover {
    color: var(--light-blue);
}

.login-btn{
    margin-right: 10px;
}