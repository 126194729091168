.login-card {
  margin-top: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.login-card h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.forgot-pass-link {
  font-size: 14px;
  text-decoration: underline;
}

.login-card button {
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.login-card .form-label {
  margin-top: 1rem;
  font-weight: 600;
}

.login-card .login-form {
  border-top: 1px solid #d9dadc;
}

/* Forgot Password */

.forgot-password {
  margin-top: 100px;
  background-color: #fff;
}

.forgot-password .form-label {
  margin: 0px;
  font-weight: 600;
}

.forgot-password button {
  width: 50%;
}

.forgot-password h1 {
  text-align: center;
  font-weight: 800;
}

.forgot-password p {
  text-align: center;
}
