.ssi-wrapper {
  position: relative;
  display: flex;
}
.ssi-wrapper .ssi-progress-wrapper {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0px;

}

.ssi-wrapper .ssi-progress-wrapper .ssi-progress-bar {
  height: 2px;
  background-color: #337ed1;
  opacity: 0.7;
  width: 0px;
  -webkit-box-shadow: 0px 0px 11px rgb(255,255,255);
  box-shadow: 0px 0px 11px rgb(255,255,255);
}
