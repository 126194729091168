
.event-info-popup {
  position: absolute;

  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  min-width: 200px;
  max-width: calc(50%);
  color: #000000;
  padding: 10px;
  min-height: 200px;
  -webkit-box-shadow: 10px 7px 19px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 7px 19px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 7px 40px -5px rgba(69, 60, 119, 0.75);
  z-index: 100;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

/* Moze sie przydać

.event-info-popup:before {
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
  background-color: #fbfcdb;
  content: ' ';
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
*/


.event-info-popup .event-info-popup-quick-menu {
  position: absolute;
  top: -1px;
  left: 100%;
  background-color: #023c81;
  z-index: 99999;
  height: 101%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 7px;
}

.event-info-popup .event-info-popup-quick-menu .bottom-icon {
  position: absolute;
  bottom: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon {

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.event-info-popup .event-info-popup-quick-menu .icon a {
  color: #fff;
  font-size: 1.5rem;
}
.event-info-popup .event-info-popup-quick-menu .icon button {
  background-color: transparent;
  border: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #fff;
}

.event-info-popup .event-info-popup-quick-menu .icon a :hover{
  transform: scale(1.2);
}

.event-info-popup .event-info-popup-quick-menu .icon.alert {
  padding: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon.alert button {
  color: #c42a2a;
  padding: 0;
}

.event-info-popup .event-info-popup-quick-menu .icon-alert button :hover {
  transform: scale(1.2);
}

.event-property-name {
  padding-right: 3px;
  font-weight: 400;
}

.event-property-value {
  font-weight: 600;
  color: #222222;
}

.event-property-name i {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
  color: #023c81;
}

.event-property-name .icon-text {
  font-size: 10px;
  font-weight: 300;
}

.event-property-description {
  display: block;
  padding: 5px;
  border-radius: 5px;
  background-color: #023b811f;
  min-height: 30px;
  font-size: 12px;
  font-weight: 500;
}

.event-info-popup hr {
  border-top: 1px solid #023c81;
  margin-top: 10px;
  margin-bottom: 10px;
}
