.fc {
  background-color: #ffff;
}
.fc .fc-toolbar.fc-header-toolbar {
  z-index: 3;
}

.fc-view-harness ::-webkit-scrollbar {
  display: none;
}

.fc-view-harness {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-inline: 20px;
}
.rbc-calendar-wrapper {
  background-color: #ffff;
  height: 90vh;
}

.fc-header-toolbar {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.fc .fc-toolbar-title {
  display: inline;
  font-size: 1.15em;
  font-weight: 500;
  color: #171616;
}
.fc-col-header {
  border-bottom: solid 1px rgba(128, 128, 128, 0.35);
}

.fc-timegrid-col.fc-day {
  border-left: solid 1px rgba(128, 128, 128, 0.35);
}

.fc .fc-timegrid-col-frame .fc-non-business {
  background: rgba(0, 0, 0, 0.4);
}

.fc-timegrid-event {
  border: 1px solid #265985;
  border-radius: 5px;
  border-left: 6px solid #023c81;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.fc-timegrid-event.closed {
  background-color: #605f5f;
  border-color: #171616;
}
.fc-timegrid-event.closed .custom-event-wrapper .patient-info,
.fc-timegrid-event.closed .custom-event-wrapper .datetime {
  color: #171616;
}

.fc-timegrid-event.approved {
  background-color: #589058;
  border-color: #244024;
}

.rbc-calendar-wrapper {
  position: relative;
  padding-top: 15px;
}

.rbc-calendar-wrapper .custom-toolbar {
  position: absolute;
  top: 0;
}

.custom-event-wrapper {
  position: absolute;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  margin-left: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}
.fc-timegrid-event {
  background-color: #2d97ee;
}
.fc-timegrid-event.active .custom-event-wrapper:hover {
  height: auto;
  overflow: visible;
  background-color: var(--fc-event-bg-color, #2d97ee);
}

.fc-daygrid-event-harness,
.month-view-cluster.month-view-event,
.custom-event-wrapper-month {
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-event-wrapper-month::after {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  font-family: Font Awesome\ 5 Free;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\F500";
  font-size: 300%;
  font-weight: 900;
  color: #2d97ee;
  min-height: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.custom-event-wrapper-month .title {
  position: absolute;
  top: 50%;
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 60%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.fc-daygrid-day-events {
  position: absolute !important;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
}

.fc-header-toolbar.fc-toolbar > div:nth-child(1)
.fc-header-toolbar.fc-toolbar > div:nth-child(3) {
  width: 25%;
}
.fc-header-toolbar.fc-toolbar > div:nth-child(2) {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.fc .fc-timegrid-slot {
  height: 2.4em;
}

.btn-primary{
  background-color: #ffff;
  border: 1px solid #023c81;
  color: #023c81;
  border-radius: 10px;
  outline: none;

}

.fc-today-button.btn.btn-primary,
.fc-today-button.btn.btn-primary:focus,
.fc-today-button.btn.btn-primary:hover{
  background-color: #FFF9DA;
  color: #eea508;
  border-bottom: 1px solid #eea508;
  border-top: 1px solid #eea508;
  border-left: 1px solid #eea508;
}
.fc-today-button.btn.btn-primary:disabled {
  background-color: #FFF9DA;
  color: #eea508;
  border-bottom: 1px solid #eea508;
  border-top: 1px solid #eea508;
  border-left: 1px solid #eea508;
}

.fc-configModal-button.btn.btn-primary:focus,
.fc-nearestFreeTermin-button.btn.btn-primary:focus {
  background-color: #fff;
  color: var(--dark-blue);
}

.fc-prev-button.btn.btn-primary:focus,
.fc-next-button.btn.btn-primary:focus {
  background-color: #fff;
  color: var(--dark-blue);
}

@media screen and (max-width: 800px) {
  .fc .fc-toolbar-title {
    font-size: 1em;
  }

  .custom-event-wrapper-month::after {
    font-size: 150%;
    top: 30%
  }

  .custom-event-wrapper-month .title  {
    width: 15px;
    height: 15px;
    top: 75%;
  }
}

