.custom-toolbar {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 18px;
    position: relative;
}

.custom-toolbar .buttons-around-label {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.custom-toolbar .buttons-around-label .label {
    color: #000;
    font-size: 100%;
}

.custom-toolbar .view-items {
    flex: 0 1 auto;
    margin-left: auto;
    margin-right: 20px;
}

.custom-toolbar .current-clinic {
    margin-right: 5px;
}

.custom-toolbar button {
    border-radius: 10px;
}
.custom-toolbar .current-doctor {
  padding-left: 10px;
  margin-right: 5px
}
.calendar-toolbar-custom-button-select {
  position: relative;
}
.calendar-toolbar-custom-button-select-dropdown {
  position: absolute;
  background-color: #ffffff;
  padding: 0;
  left: 0;
  top: 100%;
  text-align: left;
  min-width: 100%;
}

.calendar-toolbar-custom-button-select-dropdown li i {
  color: #023c81;
}

.calendar-toolbar-custom-button-select > span {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (max-width: 600px) {
    .custom-toolbar {
      display: block;
      font-size: 10px;
  }

  .custom-toolbar .btn {
      font-size: 10px;
  }
}