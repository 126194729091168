.teeth-recognition-main {
  background-color: #023c81;
  width: calc(100% - 24px);
  height: 100%;
  max-height: 100%;
  padding: 0 12px;
  position: absolute;
}

.teeth-recognition-main .recognition-box {
  margin-top: -2px;
  height: calc(100% - 64px);
  max-height: 100%;
  padding-bottom: 12px;
}

.teeth-recognition-main .recognition-box .recognition-box-list {
  background-color: #ffffff;
  height: 96%;
  padding-top: 2px;
  overflow: auto;
  position: relative;
}

.teeth-recognition-main .recognition-box .recognition-box-list {
  list-style: none;
  padding-left: 3px;
}

.teeth-recognition-main .recognition-box .recognition-box-list li {
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
}
.teeth-recognition-main .recognition-box .recognition-box-list li:hover {
  background-color: rgba(59, 153, 252, 0.75);
}

.teeth-recognition-main .recognition-box .recognition-box-list li.selected {
  background-color: #3b99fc;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div {
  padding-top: 3px;
  display: flex;

}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .background-element {
  width: 16px;
  height: 16px;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .background-element > div {
  width: 16px;
  height: 16px;
}

.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .name-element {
  line-height: 16px;
  padding-left: 3px;

}


.teeth-recognition-main .recognition-box .recognition-box-list .recognition-list-item-div .remove-element {
  line-height: 16px;
  padding-left: 3px;
  margin-left: auto;
}

.teeth-properties-list table tbody tr.active td {
    background-color: #65a2ec;
}

.teeth-properties-list ul {
    list-style: none;
    padding: 0;
}

.teeth-properties-list ul li {
    display: flex;
    vertical-align: center;
    flex-direction: row;
    margin-bottom: 5px;
    font-size: 13px;
}

.teeth-properties-list ul li .tooth-operation-legend {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #6e1229;
    margin-right: 5px;
}

.teeth-properties-list{
  margin-top: 15px;
}
