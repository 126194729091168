.shwich-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex:1 0;
}

.shwich-button > label {
  flex: 0 1 100%;
}
