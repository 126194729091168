.borderSeparatorLeft {
    border-right: solid 1px #023c81;
    margin-right: -15px;
    padding-right: 30px;
}

.borderSeparatorRight {
    margin-left: -15px;
    padding-left: 30px;
}

@media (max-width:994px){
    .borderSeparatorLeft {
        border-right: none;
    }
}
