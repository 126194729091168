.jew-main-container {
  width: 100%;
  box-sizing: border-box;
}
.jew-main-container .jew-container {
  background-color: #c5c5c5;
  box-sizing: border-box;
  position: relative;
}

.jew-main-container .jew-container .jew-up-container,
.jew-main-container .jew-container .jew-down-container {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: solid 1px #fff;
  background-color: #e37877;
}
.jew-main-container .jew-container .jew-down-container {
  top: auto;
  bottom: 0;
  border-bottom: none;
  border-top: solid 1px #fff;
}
.jew-main-container .jew-container .jew-teeth-container {
  position: absolute;
  box-sizing: border-box;
}
.jew-main-container .jew-container .jew-teeth-container .jew-teeth-up-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e37877;
  box-sizing: border-box;
}
.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e37877;
  box-sizing: border-box;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-up-container .jew-teeth-up-container-teethes {
  position: absolute;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .jew-teeth-down-container-teethes {
  position: absolute;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template {
  display: flex;
}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper{

}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-wrapper-container {
  height: 100%;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-wrapper-container.active {
  background-color: rgba(230, 230, 230, 0.7);
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part {
  height: 88%;
  display: flex;
  justify-content: center;
}

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .label-part {
  display: flex;
  justify-content: center;
  /* height: 12%; */
}


.teeth-wrapper .label-part button {
  background-color: transparent;
  border: none;
} 

.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part .svg-teeth-default
{
  fill:#fff;
  stroke:#000;
  stroke-width:.25;
  stroke-miterlimit:10
}
.jew-main-container .jew-container .jew-teeth-container .teeth-template .teeth-wrapper .teeth-part .svg-teeth-default:hover
{
  fill: rgba(250, 250, 250, .5);
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .teeth-template .teeth-wrapper .teeth-part .teeth-svg-model {
  margin-bottom: -3px;
  transform: rotate(180deg);
  overflow: hidden;
}

.jew-main-container .jew-container .jew-teeth-container .jew-teeth-down-container .teeth-template .teeth-wrapper .teeth-part .teeth-svg-model svg {

}
