
.notification-wrapper {
  color: #fff;
  padding: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
}

.notification-wrapper .toast {
  background-color: #ffffff;
}

.notification-wrapper .icon-wrapper {
  margin-right: 5px;
  font-size: 1.5rem;
  position: relative;
}

.notification-wrapper .icon-wrapper .icon-button {
    border: none;
    color: #fff;
}

.notification-wrapper .toast-header {
  justify-content: space-between;

}

.notification-wrapper .toast-header .data {
  margin-left: auto;
}

.notification-wrapper .icon-wrapper .badge-wrapper {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 1rem;
}

.notification-wrapper .toast-wrapper {
  position: absolute;
  min-width: 350px;
  top: 110%;
  right: 0;
  z-index: 4;

  overflow: auto;
  max-height: 80vh;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.notification-wrapper .body-wrapper {
  color: #2a2a2a;
}

.toast-wrapper .notification-header{
  color: #2a2a2a;
  background: #fff;
}