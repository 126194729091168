.pdf-viewer-content {
    min-height: 100vh;
    padding-bottom: 40px;
}

.pdf-viewer-content .pages-toolbar{
    position: fixed;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.pdf-viewer-content .pages-toolbar .tool {
    margin: 0 5px 0 5px;
    text-align: center;
}