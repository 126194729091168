.menu-user .dropdown-toggle {
    opacity: 0;
    border: solid 1px #0f509e;
    height: 50px;
    width: 50px;
}

.menu-user .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: solid 1px #252525;
    background-color: #d8d8d8;
    position: relative;
}
.menu-user .profile-image .initials {
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18pt;
  font-weight: bold;
  margin-left: -1px;
}

.menu-user .profile-image .dropdown {
  position: absolute;
  top: 0;
  left: 0;
}

.menu-content .menu-menu .menu-user .dropdown-menu a {
    padding: 2px 5px;
}

.menu-content .menu-menu .menu-user .dropdown-menu a > a{
    padding: 0;
}

.dropdown-menu.show {
    margin-right: 50%;
    margin-top: auto;
}