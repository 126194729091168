.nav-brand img{
    width: 120px;
    margin-right: 10px;
}

.nav-brand {
    font-size: 14px;
}

@media (max-width:764px){
    .nav-brand img{
        width: 100px;
    }
}

